<template>
  <v-container>
    <template v-if="hasExisingBudgetCodes">
      <v-alert color="info" dark> Please remove budget codes first to update <b>Budget Code Structure</b> </v-alert>
    </template>
    <v-form :readonly="isFormReadOnly">
      <v-card>
        <v-card-title>Budget Code Structure</v-card-title>
        <v-card-text md="1">
          <v-alert v-show="!isClean" dense text type="info">
            Changes were detected, Kindly click the <strong>SAVE</strong> button above to confirm these changes.
          </v-alert>
        </v-card-text>
        <v-card-text>
          <v-row dense v-for="(section, index) in structure" :key="index">
            <v-col cols="12" md="1" class="center-text">
              <h3>{{ index + 1 }}</h3>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                :value="section.name"
                v-model.trim="$v.structure.$each[index].name.$model"
                label="Code Description"
                :prepend-icon="!isFormReadOnly ? 'mdi-delete' : undefined"
                @click:prepend="deleteRow(index)"
                required
                :error-messages="handleErrors($v.structure.$each[index].name)"
                @blur="$v.structure.$each[index].name.$touch()"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="1">
              <v-text-field
                :value="section.numChar"
                v-model.trim="$v.structure.$each[index].numChar.$model"
                label="Num Char"
                required
                type="number"
                :error-messages="handleErrors($v.structure.$each[index].numChar)"
                @blur="$v.structure.$each[index].numChar.$touch()"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-checkbox
                :value="section.locationDependent"
                v-model="$v.structure.$each[index].locationDependent.$model"
                label="Loc/Dept Code"
                required
                @change="handleLocationSelected($event, index)"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { toLowerCamelCase, handleErrors } from '@/util';
import { GET_CONFIG, SET_CONFIG } from '@/store/modules/Config/actions';

export default {
  mixins: [validationMixin],
  inject: ['eventHub'],

  components: {},
  data: () => ({
    structure: [],
    copyStructure: [],
    toLowerCamelCase,
    handleErrors,
  }),
  validations: {
    structure: {
      $each: {
        name: { required },
        numChar: { required },
        locationDependent: { required },
      },
    },
  },
  computed: {
    ...mapGetters('user', ['me']),
    ...mapGetters('budgetCode', ['hasExisingBudgetCodes']),
    isFormReadOnly() {
      if (this.hasExisingBudgetCodes) return true;
      if (this.me.is.superAdmin || this.me.is.transportationAdmin) return false;

      return true;
    },
    isClean() {
      return (
        this.copyStructure.length === this.structure.length &&
        this.structure.every((element, index) => element === this.copyStructure[index])
      );
    },
  },
  async mounted() {
    await this.getBCConfig();
    await this.getBudgetCodes();
  },
  created() {
    this.eventHub.$on('saveBudgetCode', () => this.save());
    this.eventHub.$on('addBudgetCodeSection', () => this.addSection());
  },
  beforeDestroy() {
    this.eventHub.$off('saveBudgetCode');
    this.eventHub.$off('addBudgetCodeSection');
  },
  methods: {
    ...mapActions('config', [GET_CONFIG, SET_CONFIG]),
    ...mapActions('budgetCode', ['getBudgetCodes']),
    async getBCConfig() {
      this.bcConfig = await this.getConfig('budgetCode');
      this.structure = this.bcConfig;
      this.copyStructure = [...this.structure];
      if (!this.structure) this.structure = [];
      if (!this.structure.length) this.addSection();
    },
    addSection() {
      this.structure.push({ name: '', numChar: 0, locationDependent: false });
    },
    async save() {
      if (this.$v.structure.$invalid) {
        this.$v.structure.$touch();
        this.$myalert.error('Please complete all required fields');
        return;
      }
      try {
        await this.setConfig({ name: 'budgetCode', value: this.$data.structure });
        this.$myalert.success('Budget Code Structure saved');
        this.copyStructure = [...this.structure];
      } catch (e) {
        this.$myalert.error(e.message);
      }
    },
    deleteRow(index) {
      this.$data.structure.splice(index, 1);
    },
    handleLocationSelected(e, index) {
      for (let i = 0; i < this.structure.length; i++) if (i != index) this.structure[i].locationDependent = false;
      this.$v.structure.$each[index].locationDependent.$touch();
    },
  },
};
</script>

<style scoped>
.center-text {
  margin: auto;
  text-align: center;
}
</style>
